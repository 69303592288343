import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Divider,
  Avatar,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Badge,
  Tooltip
} from '@mui/material';
import {
  Dashboard as DashboardIcon,
  Business as BusinessIcon,
  Home as HomeIcon,
  GridView as GridViewIcon,
  CalendarMonth as CalendarIcon,
  Message as MessageIcon,
  Person as PersonIcon,
  School as SchoolIcon,
  Add as AddIcon,
  Notifications as NotificationsIcon,
  Settings as SettingsIcon,
  Logout as LogoutIcon,
  KeyboardArrowDown as ArrowDownIcon
} from '@mui/icons-material';

// Моковые данные пользователя (потом заменить на реальные)
const mockUser = {
  name: 'John Doe',
  email: 'john.doe@example.com',
  role: 'Senior Agent',
  avatar: '/path/to/avatar.jpg',
  notifications: 3
};

const navigationItems = [
  { path: '/', label: 'Dashboard', icon: <DashboardIcon /> },
  { path: '/company', label: 'Company', icon: <BusinessIcon /> },
  { path: '/properties', label: 'Properties', icon: <HomeIcon /> },
  { path: '/units', label: 'Units', icon: <GridViewIcon /> },
  { path: '/calendar', label: 'Calendar', icon: <CalendarIcon /> },
  { path: '/messages', label: 'Messages', icon: <MessageIcon /> },
  { path: '/requests', label: 'Client Requests', icon: <PersonIcon /> },
  { path: '/agent-training', label: 'Agent Training', icon: <SchoolIcon /> }
];

const Navigation = () => {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState<null | HTMLElement>(null);

  const handleProfileClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationClick = (event: React.MouseEvent<HTMLElement>) => {
    setNotificationAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setNotificationAnchorEl(null);
  };

  return (
    <Box
      component="nav"
      sx={{
        width: 280,
        flexShrink: 0,
        borderRight: 1,
        borderColor: 'divider',
        bgcolor: 'background.paper',
        height: '100vh',
        position: 'sticky',
        top: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {/* User Profile Section */}
      <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Avatar
            src={mockUser.avatar}
            sx={{ width: 40, height: 40, mr: 2 }}
          >
            {mockUser.name[0]}
          </Avatar>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1" noWrap>
              {mockUser.name}
            </Typography>
            <Typography variant="body2" color="text.secondary" noWrap>
              {mockUser.role}
            </Typography>
          </Box>
          <Box>
            <Tooltip title="Notifications">
              <IconButton onClick={handleNotificationClick}>
                <Badge badgeContent={mockUser.notifications} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <IconButton onClick={handleProfileClick}>
              <ArrowDownIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>

      {/* Navigation Items */}
      <List sx={{ py: 2, flexGrow: 1 }}>
        {navigationItems.map(item => (
          <ListItem key={item.path} disablePadding>
            <ListItemButton
              component={Link}
              to={item.path}
              selected={location.pathname === item.path}
              sx={{
                py: 1.5,
                '&.Mui-selected': {
                  bgcolor: 'primary.lighter',
                  color: 'primary.main',
                  '&:hover': {
                    bgcolor: 'primary.lighter',
                  },
                  '& .MuiListItemIcon-root': {
                    color: 'primary.main',
                  }
                }
              }}
            >
              <ListItemIcon 
                sx={{ 
                  minWidth: 40,
                  color: location.pathname === item.path ? 'primary.main' : 'text.secondary'
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText 
                primary={item.label}
                primaryTypographyProps={{
                  fontSize: 14,
                  fontWeight: location.pathname === item.path ? 600 : 400
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}

        {/* Add Test Generator Button */}
        <ListItem sx={{ mt: 2 }}>
          <ListItemButton
            component={Link}
            to="/agent-training/test-generator"
            sx={{
              bgcolor: 'primary.main',
              color: 'white',
              borderRadius: 1,
              '&:hover': {
                bgcolor: 'primary.dark',
              }
            }}
          >
            <ListItemIcon sx={{ color: 'white', minWidth: 40 }}>
              <AddIcon />
            </ListItemIcon>
            <ListItemText 
              primary="Создать тест"
              primaryTypographyProps={{
                fontSize: 14,
                fontWeight: 600
              }}
            />
          </ListItemButton>
        </ListItem>
      </List>

      {/* Profile Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem>
          <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          Профиль
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          Настройки
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Выйти
        </MenuItem>
      </Menu>

      {/* Notifications Menu */}
      <Menu
        anchorEl={notificationAnchorEl}
        open={Boolean(notificationAnchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          sx: { width: 320 }
        }}
      >
        <MenuItem>
          <Typography variant="subtitle2" noWrap>
            Новый тест доступен
          </Typography>
        </MenuItem>
        <MenuItem>
          <Typography variant="subtitle2" noWrap>
            Сертификат выдан
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem>
          <Typography variant="body2" color="primary">
            Показать все уведомления
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default Navigation; 