import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from './theme';
import { AppProvider } from './context';
import { Navigation } from './components';
import { routes } from './routes';
import { TrainingDashboard, TestGenerator, Certification, CoursePage, TestPage } from './components/AgentTraining';

function App() {
  return (
    <BrowserRouter>
      <AppProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Box sx={{ display: 'flex' }}>
            <Navigation />
            <Box component="main" sx={{ flexGrow: 1, height: '100vh', overflow: 'auto' }}>
              <Routes>
                {routes.map((route) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={route.element}
                  />
                ))}
                <Route path="/agent-training" element={<TrainingDashboard />} />
                <Route path="/agent-training/dashboard" element={<TrainingDashboard />} />
                <Route path="/agent-training/test-generator" element={<TestGenerator />} />
                <Route path="/agent-training/certification" element={<Certification />} />
                <Route path="/agent-training/course/:courseId" element={<CoursePage />} />
                <Route path="/agent-training/test/:courseId/:lessonId" element={<TestPage />} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </Box>
          </Box>
        </ThemeProvider>
      </AppProvider>
    </BrowserRouter>
  );
}

export default App; 