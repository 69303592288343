import { Unit } from '../types/unit';

// Функция для генерации номера юнита на основе этажа и позиции
const generateUnitNumber = (floor: number, position: number): string => {
  return `${floor.toString().padStart(2, '0')}${position.toString().padStart(2, '0')}`;
};

// Функция для генерации цены на основе этажа и площади
const generatePrice = (floor: number, area: number): number => {
  const basePrice = 150000; // Базовая цена за м²
  const floorMultiplier = 1 + (floor * 0.01); // Повышение цены на 1% за этаж
  return Math.round(area * basePrice * floorMultiplier);
};

// Шаблоны планировок для разных типов юнитов
const UNIT_LAYOUTS = {
  "studio": {
    area: [35, 40],
    bedrooms: 0,
    path: `M 0,0 h 250 v 300 h -250 Z
      M 20,20 h 210 v 180 h -210 Z
      M 20,220 h 80 v 60 h -80 Z
      M 120,220 h 110 v 60 h -110 Z`,
    rooms: {
      mainRoom: { area: 25.5, label: 'Main Room' },
      bathroom: { area: 4.8, label: 'Bathroom' },
      balcony: { area: 5.0, label: 'Balcony' }
    }
  },
  "1-bedroom": {
    area: [40.27, 45.27],
    bedrooms: 1,
    path: `M 0,0 h 300 v 400 h -300 Z
      M 0,150 h 300
      M 150,0 v 400
      M 20,20 h 110 v 110 h -110 Z
      M 20,130 h 110 v 150 h -110 Z
      M 170,20 h 110 v 180 h -110 Z
      M 170,220 h 50 v 60 h -50 Z
      M 230,220 h 50 v 60 h -50 Z
      M 20,300 h 260 v 80 h -260 Z`,
    rooms: {
      livingRoom: { area: 17.45, label: 'Living Room' },
      bedroom: { area: 14.12, label: 'Bedroom' },
      kitchen: { area: 4.06, label: 'Kitchen' },
      bathroom: { area: 4.64, label: 'Bathroom' },
      balcony: { area: 5.00, label: 'Balcony' }
    }
  },
  "2-bedroom": {
    area: [65, 75],
    bedrooms: 2,
    path: `M 0,0 h 400 v 500 h -400 Z
      M 0,200 h 400
      M 200,0 v 500
      M 20,20 h 160 v 160 h -160 Z
      M 220,20 h 160 v 160 h -160 Z
      M 220,220 h 160 v 160 h -160 Z
      M 20,220 h 70 v 80 h -70 Z
      M 110,220 h 70 v 80 h -70 Z
      M 20,320 h 160 v 80 h -160 Z`,
    rooms: {
      livingRoom: { area: 25.5, label: 'Living Room' },
      masterBedroom: { area: 18.2, label: 'Master Bedroom' },
      bedroom: { area: 14.8, label: 'Bedroom' },
      kitchen: { area: 8.5, label: 'Kitchen' },
      bathroom1: { area: 4.8, label: 'Bathroom 1' },
      bathroom2: { area: 4.2, label: 'Bathroom 2' },
      balcony: { area: 7.0, label: 'Balcony' }
    }
  },
  "3-bedroom": {
    area: [85, 120],
    bedrooms: 3,
    path: `M 0,0 h 500 v 600 h -500 Z
      M 0,250 h 500
      M 250,0 v 600
      M 20,20 h 210 v 210 h -210 Z
      M 270,20 h 210 v 160 h -210 Z
      M 270,200 h 210 v 160 h -210 Z
      M 20,270 h 210 v 160 h -210 Z
      M 270,380 h 80 v 100 h -80 Z
      M 370,380 h 80 v 100 h -80 Z
      M 20,450 h 210 v 80 h -210 Z
      M 270,500 h 210 v 80 h -210 Z`,
    rooms: {
      livingRoom: { area: 35.0, label: 'Living Room' },
      masterBedroom: { area: 22.0, label: 'Master Bedroom' },
      bedroom1: { area: 18.0, label: 'Bedroom 1' },
      bedroom2: { area: 16.0, label: 'Bedroom 2' },
      kitchen: { area: 12.0, label: 'Kitchen' },
      bathroom1: { area: 6.5, label: 'Bathroom 1' },
      bathroom2: { area: 5.5, label: 'Bathroom 2' },
      balcony1: { area: 8.0, label: 'Balcony 1' },
      balcony2: { area: 7.0, label: 'Balcony 2' }
    }
  },
  "penthouse": {
    area: [120, 200],
    bedrooms: 4,
    path: `M 0,0 h 600 v 800 h -600 Z
      M 0,300 h 600
      M 20,20 h 280 v 260 h -280 Z
      M 320,20 h 260 v 200 h -260 Z
      M 320,240 h 260 v 200 h -260 Z
      M 20,340 h 260 v 200 h -260 Z
      M 320,460 h 260 v 200 h -260 Z
      M 20,560 h 100 v 120 h -100 Z
      M 140,560 h 100 v 120 h -100 Z
      M 20,700 h 260 v 80 h -260 Z
      M 320,700 h 260 v 80 h -260 Z`,
    rooms: {
      livingRoom: { area: 45.0, label: 'Living Room' },
      masterBedroom: { area: 28.0, label: 'Master Bedroom' },
      bedroom1: { area: 22.0, label: 'Bedroom 1' },
      bedroom2: { area: 20.0, label: 'Bedroom 2' },
      bedroom3: { area: 18.0, label: 'Bedroom 3' },
      kitchen: { area: 15.0, label: 'Kitchen' },
      bathroom1: { area: 8.5, label: 'Bathroom 1' },
      bathroom2: { area: 7.5, label: 'Bathroom 2' },
      terrace1: { area: 12.0, label: 'Terrace 1' },
      terrace2: { area: 12.0, label: 'Terrace 2' }
    }
  }
} as const;

// Функция для генерации юнитов на этаже
const generateFloorUnits = (floor: number, building: string): Unit[] => {
  const units: Unit[] = [];
  const unitsPerFloor = 12; // 12 юнитов на этаж как на скриншоте
  
  for (let position = 1; position <= unitsPerFloor; position++) {
    // Определяем тип планировки на основе позиции
    let layout: keyof typeof UNIT_LAYOUTS;
    if (floor === 30) {
      layout = 'penthouse';
    } else if (position <= 2) {
      layout = '3-bedroom';
    } else if (position <= 6) {
      layout = '2-bedroom';
    } else if (position <= 10) {
      layout = '1-bedroom';
    } else {
      layout = 'studio';
    }

    const layoutInfo = UNIT_LAYOUTS[layout];
    const area = Math.round(
      layoutInfo.area[0] + Math.random() * (layoutInfo.area[1] - layoutInfo.area[0])
    );

    // Определяем статус на основе вероятностей
    const statusRandom = Math.random();
    let status: Unit['status'];
    if (statusRandom < 0.4) {
      status = 'available';
    } else if (statusRandom < 0.6) {
      status = 'reserved';
    } else if (statusRandom < 0.9) {
      status = 'sold';
    } else {
      status = 'unavailable';
    }

    // Определяем вид из окна на основе позиции и этажа
    let windowView: Unit['windowView'];
    if (position <= 3) {
      windowView = 'sea';
    } else if (position <= 6) {
      windowView = 'mountain';
    } else if (position <= 9) {
      windowView = 'garden';
    } else {
      windowView = 'city';
    }

    const unit: Unit = {
      id: `${building}-${generateUnitNumber(floor, position)}`,
      number: generateUnitNumber(floor, position),
      floor,
      building,
      status,
      area,
      bedrooms: layoutInfo.bedrooms,
      price: generatePrice(floor, area),
      windowView,
      pricePerSqm: Math.round(generatePrice(floor, area) / area),
      layout,
      completion: floor <= 10 ? '2024 Q4' : floor <= 20 ? '2025 Q2' : '2025 Q4',
      furnished: Math.random() > 0.5,
      path: layoutInfo.path // Добавляем SVG-путь для отрисовки планировки
    };

    units.push(unit);
  }

  return units;
};

const generateMockUnits = (): Unit[] => {
  const units: Unit[] = [];
  const buildings = ['1', '2', '3'];
  const maxFloors = 30;

  for (const building of buildings) {
    for (let floor = 1; floor <= maxFloors; floor++) {
      units.push(...generateFloorUnits(floor, building));
    }
  }

  return units.sort((a, b) => {
    if (a.building !== b.building) return a.building.localeCompare(b.building);
    if (a.floor !== b.floor) return a.floor - b.floor;
    return a.number.localeCompare(b.number);
  });
};

export const mockUnits = generateMockUnits();