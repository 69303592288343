import { TrainingModule, Agent, Test, CourseModule, Material } from '../types/training';

export const mockTrainingModules: TrainingModule[] = [
  {
    id: 'module1',
    title: 'Базовый курс по продажам',
    description: 'Основы продаж недвижимости',
    contentUrl: '/training/sales-basics'
  }
];

// Материалы для курсов
export const mockMaterials: Material[] = [
  {
    id: 'mat-1',
    type: 'video',
    title: 'Введение в рынок недвижимости',
    url: '/materials/videos/market-intro.mp4'
  },
  {
    id: 'mat-2',
    type: 'pdf',
    title: 'Справочник терминов',
    url: '/materials/docs/terms-guide.pdf'
  },
  {
    id: 'mat-3',
    type: 'presentation',
    title: 'Типы недвижимости',
    url: '/materials/presentations/property-types.pptx'
  }
];

// Расширенные курсы с материалами и детальными тестами
export const mockCourseModules: CourseModule[] = [
  {
    id: 'course1',
    title: 'Основы работы с клиентами',
    description: 'Базовые принципы работы с клиентами в сфере недвижимости',
    level: 'Начальный',
    duration: '2 часа',
    totalScore: 100,
    contentUrl: '/courses/client-basics',
    requiredScore: 70,
    materials: ['mat-1', 'mat-2'],
    progress: {
      enrolled: 45,
      completed: 32,
      averageScore: 85
    },
    lessons: [
      {
        id: 'lesson1-1',
        title: 'Первичный контакт с клиентом',
        content: `
          # Установление первого контакта
          
          ## Ключевые моменты:
          - Правильное приветствие и представление
          - Создание комфортной атмосферы
          - Активное слушание
          - Определение типа клиента

          ## Основные правила:
          1. Всегда начинайте с улыбки и зрительного контакта
          2. Представьтесь полным именем и должностью
          3. Уточните, как удобно обращаться к клиенту
          4. Проявите искренний интерес к запросу клиента

          ## Практические рекомендации:
          - Используйте открытые вопросы
          - Фиксируйте ключевые моменты разговора
          - Определите предпочтительный способ коммуникации
          - Установите временные рамки взаимодействия
        `,
        materials: ['mat-1'],
        estimatedTime: 45,
        test: {
          timeLimit: 1800,
          questions: [
            {
              id: 'q1-1',
              text: 'Какой первый шаг в работе с новым клиентом?',
              options: [
                'Выявление потребностей',
                'Презентация объекта',
                'Обсуждение цены',
                'Подписание договора'
              ],
              correctAnswer: 'Выявление потребностей',
              points: 10,
              explanation: 'Выявление потребностей позволяет понять цели и ожидания клиента'
            }
          ]
        }
      },
      {
        id: 'lesson1-2',
        title: 'Выявление потребностей клиента',
        content: `
          # Анализ потребностей клиента

          ## Основные аспекты:
          - Техника SPIN-вопросов
          - Определение явных и скрытых потребностей
          - Работа с приоритетами клиента
          - Фиксация требований

          ## Ключевые вопросы для выявления потребностей:
          1. Ситуационные вопросы
          2. Проблемные вопросы
          3. Извлекающие вопросы
          4. Направляющие вопросы

          ## Практические инструменты:
          - Чек-лист потребностей
          - Матрица приоритетов
          - Карта пожеланий клиента
          - Техника уточняющих вопросов
        `,
        materials: ['mat-2'],
        estimatedTime: 50,
        test: {
          timeLimit: 1800,
          questions: [
            {
              id: 'q1-2',
              text: 'Что необходимо подготовить перед показом объекта?',
              options: [
                'Полное досье объекта и планировку',
                'Только ключи',
                'Только фотографии',
                'Только цену'
              ],
              correctAnswer: 'Полное досье объекта и планировку',
              points: 15,
              explanation: 'Полная подготовка документации позволяет провести эффективную презентацию'
            }
          ]
        }
      },
      {
        id: 'lesson1-3',
        title: 'Презентация объектов недвижимости',
        content: `
          # Эффективная презентация объектов

          ## Структура презентации:
          - Подготовка к показу
          - Маршрут осмотра
          - Акценты на преимуществах
          - Работа с возражениями

          ## Техники презентации:
          1. Правило "трех преимуществ"
          2. Метод сравнения
          3. Визуализация будущего
          4. Истории успеха

          ## Чек-лист подготовки:
          - Проверка состояния объекта
          - Подготовка документации
          - Планировка маршрута показа
          - Подготовка ответов на типичные вопросы
        `,
        materials: ['mat-1'],
        estimatedTime: 55,
        test: {
          timeLimit: 1800,
          questions: [
            {
              id: 'q1-3',
              text: 'Что необходимо подготовить перед показом объекта?',
              options: [
                'Полное досье объекта и планировку',
                'Только ключи',
                'Только фотографии',
                'Только цену'
              ],
              correctAnswer: 'Полное досье объекта и планировку',
              points: 15,
              explanation: 'Полная подготовка документации позволяет провести эффективную презентацию'
            }
          ]
        }
      },
      {
        id: 'lesson1-4',
        title: 'Работа с возражениями',
        content: `
          # Техники работы с возражениями

          ## Типы возражений:
          - Ценовые возражения
          - Локационные возражения
          - Качественные возражения
          - Временные возражения

          ## Алгоритм работы с возражениями:
          1. Выслушать возражение полностью
          2. Согласиться с правом клиента на сомнения
          3. Задать уточняющие вопросы
          4. Предложить решение

          ## Практические приемы:
          - Метод "Да, и..."
          - Техника бумеранга
          - Метод сравнения
          - Истории успешных кейсов
        `,
        materials: ['mat-2'],
        estimatedTime: 60,
        test: {
          timeLimit: 1800,
          questions: [
            {
              id: 'q1-4',
              text: 'Что необходимо подготовить перед показом объекта?',
              options: [
                'Полное досье объекта и планировку',
                'Только ключи',
                'Только фотографии',
                'Только цену'
              ],
              correctAnswer: 'Полное досье объекта и планировку',
              points: 15,
              explanation: 'Полная подготовка документации позволяет провести эффективную презентацию'
            }
          ]
        }
      },
      {
        id: 'lesson1-5',
        title: 'Ведение переговоров',
        content: `
          # Искусство переговоров

          ## Этапы переговоров:
          - Подготовка к переговорам
          - Установление контакта
          - Выявление интересов
          - Поиск решений
          - Заключение соглашения

          ## Стратегии переговоров:
          1. Win-Win подход
          2. Позиционные переговоры
          3. Принципиальные переговоры
          4. Компромиссный подход

          ## Техники влияния:
          - Социальное доказательство
          - Взаимный обмен
          - Последовательность
          - Дефицит
        `,
        materials: ['mat-1'],
        estimatedTime: 65,
        test: {
          timeLimit: 1800,
          questions: [
            {
              id: 'q1-5',
              text: 'Что необходимо подготовить перед показом объекта?',
              options: [
                'Полное досье объекта и планировку',
                'Только ключи',
                'Только фотографии',
                'Только цену'
              ],
              correctAnswer: 'Полное досье объекта и планировку',
              points: 15,
              explanation: 'Полная подготовка документации позволяет провести эффективную презентацию'
            }
          ]
        }
      },
      {
        id: 'lesson1-6',
        title: 'Документальное оформление сделок',
        content: `
          # Работа с документами

          ## Основные документы:
          - Договор купли-продажи
          - Акт приема-передачи
          - Документы на собственность
          - Технические документы

          ## Порядок оформления:
          1. Проверка документов
          2. Подготовка договора
          3. Согласование условий
          4. Подписание документов

          ## Важные аспекты:
          - Проверка юридической чистоты
          - Соблюдение сроков
          - Регистрация сделки
          - Передача документов
        `,
        materials: ['mat-2'],
        estimatedTime: 70,
        test: {
          timeLimit: 1800,
          questions: [
            {
              id: 'q1-6',
              text: 'Что необходимо подготовить перед показом объекта?',
              options: [
                'Полное досье объекта и планировку',
                'Только ключи',
                'Только фотографии',
                'Только цену'
              ],
              correctAnswer: 'Полное досье объекта и планировку',
              points: 15,
              explanation: 'Полная подготовка документации позволяет провести эффективную презентацию'
            }
          ]
        }
      },
      {
        id: 'lesson1-7',
        title: 'Послепродажное обслуживание',
        content: `
          # Сопровождение после сделки

          ## Основные задачи:
          - Поддержание контакта
          - Решение возникающих вопросов
          - Получение рекомендаций
          - Развитие отношений

          ## План сопровождения:
          1. Регулярные контакты
          2. Поздравления с праздниками
          3. Информирование о новинках
          4. Программа лояльности

          ## Инструменты работы:
          - CRM-система
          - Календарь контактов
          - База знаний
          - Шаблоны коммуникаций
        `,
        materials: ['mat-1'],
        estimatedTime: 45,
        test: {
          timeLimit: 1800,
          questions: [
            {
              id: 'q1-7',
              text: 'Что необходимо подготовить перед показом объекта?',
              options: [
                'Полное досье объекта и планировку',
                'Только ключи',
                'Только фотографии',
                'Только цену'
              ],
              correctAnswer: 'Полное досье объекта и планировку',
              points: 15,
              explanation: 'Полная подготовка документации позволяет провести эффективную презентацию'
            }
          ]
        }
      },
      {
        id: 'lesson1-8',
        title: 'Управление базой клиентов',
        content: `
          # Работа с клиентской базой

          ## Структура базы:
          - Контактная информация
          - История взаимодействия
          - Предпочтения клиентов
          - Статус отношений

          ## Сегментация клиентов:
          1. По статусу сделки
          2. По типу запроса
          3. По платежеспособности
          4. По активности

          ## Инструменты управления:
          - Автоматизация процессов
          - Аналитика данных
          - Планирование активностей
          - Оценка эффективности
        `,
        materials: ['mat-2'],
        estimatedTime: 50,
        test: {
          timeLimit: 1800,
          questions: [
            {
              id: 'q1-8',
              text: 'Что необходимо подготовить перед показом объекта?',
              options: [
                'Полное досье объекта и планировку',
                'Только ключи',
                'Только фотографии',
                'Только цену'
              ],
              correctAnswer: 'Полное досье объекта и планировку',
              points: 15,
              explanation: 'Полная подготовка документации позволяет провести эффективную презентацию'
            }
          ]
        }
      },
      {
        id: 'lesson1-9',
        title: 'Маркетинг и продвижение',
        content: `
          # Маркетинговые инструменты

          ## Каналы продвижения:
          - Социальные сети
          - Email-маркетинг
          - Контекстная реклама
          - Партнерские программы

          ## Создание контента:
          1. Фото и видео материалы
          2. Описания объектов
          3. Экспертные статьи
          4. Отзывы клиентов

          ## Стратегии продвижения:
          - Таргетированная реклама
          - Контент-маркетинг
          - Личный бренд
          - Нетворкинг
        `,
        materials: ['mat-1'],
        estimatedTime: 55,
        test: {
          timeLimit: 1800,
          questions: [
            {
              id: 'q1-9',
              text: 'Что необходимо подготовить перед показом объекта?',
              options: [
                'Полное досье объекта и планировку',
                'Только ключи',
                'Только фотографии',
                'Только цену'
              ],
              correctAnswer: 'Полное досье объекта и планировку',
              points: 15,
              explanation: 'Полная подготовка документации позволяет провести эффективную презентацию'
            }
          ]
        }
      },
      {
        id: 'lesson1-10',
        title: 'Аналитика и отчетность',
        content: `
          # Анализ эффективности работы

          ## Ключевые показатели:
          - Конверсия сделок
          - Средний чек
          - Время закрытия сделки
          - Удовлетворенность клиентов

          ## Виды отчетов:
          1. Ежедневные отчеты
          2. Еженедельная статистика
          3. Месячные показатели
          4. Квартальные результаты

          ## Инструменты анализа:
          - Статистические данные
          - Воронка продаж
          - Обратная связь
          - Сравнительный анализ
        `,
        materials: ['mat-2'],
        estimatedTime: 60,
        test: {
          timeLimit: 1800,
          questions: [
            {
              id: 'q1-10',
              text: 'Что необходимо подготовить перед показом объекта?',
              options: [
                'Полное досье объекта и планировку',
                'Только ключи',
                'Только фотографии',
                'Только цену'
              ],
              correctAnswer: 'Полное досье объекта и планировку',
              points: 15,
              explanation: 'Полная подготовка документации позволяет провести эффективную презентацию'
            }
          ]
        }
      }
    ],
    prerequisites: []
  },
  {
    id: 'course2',
    title: 'Маркетинг недвижимости',
    description: 'Современные методы продвижения объектов недвижимости',
    level: 'Средний',
    duration: '4 часа',
    totalScore: 150,
    contentUrl: '/courses/marketing',
    requiredScore: 75,
    materials: ['mat-2', 'mat-3'],
    progress: {
      enrolled: 35,
      completed: 22,
      averageScore: 82
    },
    lessons: [
      {
        id: 'lesson2-1',
        title: 'Digital-маркетинг в недвижимости',
        content: `
          - Социальные сети
          - Таргетированная реклама
          - Email-маркетинг
          - Контент-маркетинг
        `,
        materials: ['mat-3'],
        estimatedTime: 90,
        test: {
          timeLimit: 2700,
          questions: [
            {
              id: 'q2-1',
              text: 'Какой канал наиболее эффективен для продвижения премиальной недвижимости?',
              options: [
                'Instagram и Facebook',
                'Газеты',
                'Радио',
                'Листовки'
              ],
              correctAnswer: 'Instagram и Facebook',
              points: 20,
              explanation: 'Социальные сети позволяют точно таргетировать целевую аудиторию'
            }
          ]
        }
      }
    ],
    prerequisites: ['course1']
  },
  {
    id: 'course3',
    title: 'Инвестиции в недвижимость',
    description: 'Анализ инвестиционной привлекательности объектов',
    level: 'Продвинутый',
    duration: '6 часов',
    totalScore: 200,
    contentUrl: '/courses/investments',
    requiredScore: 85,
    materials: ['mat-1', 'mat-3'],
    progress: {
      enrolled: 25,
      completed: 18,
      averageScore: 88
    },
    lessons: [
      {
        id: 'lesson3-1',
        title: 'Оценка инвестиционного потенциала',
        content: `
          - Анализ локации
          - Расчет ROI
          - Оценка рисков
          - Прогнозирование доходности
        `,
        materials: ['mat-1'],
        estimatedTime: 120,
        test: {
          timeLimit: 3600,
          questions: [
            {
              id: 'q3-1',
              text: 'Какой показатель наиболее важен при оценке инвестиционной привлекательности?',
              options: [
                'ROI (Return on Investment)',
                'Только цена объекта',
                'Только локация',
                'Только состояние объекта'
              ],
              correctAnswer: 'ROI (Return on Investment)',
              points: 25,
              explanation: 'ROI позволяет оценить эффективность инвестиций в процентном соотношении'
            }
          ]
        }
      }
    ],
    prerequisites: ['course1', 'course2']
  }
];

export const mockAgents: Agent[] = [
  {
    id: 'agent1',
    name: 'Иван Иванов',
    email: 'ivan.ivanov@example.com',
    role: 'Senior Agent',
    completedModules: ['course1', 'course2'],
    certificates: ['cert1', 'cert2'],
    progress: {
      currentCourse: 'course3',
      lastActivity: '2024-03-15',
      testResults: [
        {
          courseId: 'course1',
          score: 95,
          completedDate: '2024-02-01',
          timeSpent: 115 // минут
        },
        {
          courseId: 'course2',
          score: 88,
          completedDate: '2024-03-01',
          timeSpent: 180
        }
      ]
    }
  },
  {
    id: 'agent2',
    name: 'Мария Петрова',
    email: 'maria.petrova@example.com',
    role: 'Junior Agent',
    completedModules: ['course1'],
    certificates: ['cert1'],
    progress: {
      currentCourse: 'course2',
      lastActivity: '2024-03-14',
      testResults: [
        {
          courseId: 'course1',
          score: 82,
          completedDate: '2024-02-15',
          timeSpent: 130
        }
      ]
    }
  }
];

export const mockTests: Test[] = [
  {
    timeLimit: 1800,
    questions: [
      {
        id: 'q1',
        text: 'Какой основной принцип работы с клиентом?',
        options: [
          'Индивидуальный подход',
          'Максимальная прибыль',
          'Быстрая сделка',
          'Минимум контактов'
        ],
        correctAnswer: 'Индивидуальный подход',
        points: 10
      }
    ]
  }
];

export const mockCertificates = [
  {
    id: 'cert1',
    agentId: 'agent1',
    moduleId: 'course1',
    issueDate: '2024-01-01',
    validUntil: '2024-12-31'
  },
  {
    id: 'cert2',
    agentId: 'agent1',
    moduleId: 'course2',
    issueDate: '2024-02-01',
    validUntil: '2024-12-31'
  }
];

// Статистика по курсам
export const mockCourseStats = {
  totalEnrollments: 156,
  completionRate: 72,
  averageScore: 84,
  popularCourses: [
    {
      courseId: 'course1',
      enrollments: 45,
      averageRating: 4.5
    },
    {
      courseId: 'course2',
      enrollments: 28,
      averageRating: 4.8
    }
  ],
  monthlyProgress: [
    { month: 'Январь', completions: 12, newEnrollments: 18 },
    { month: 'Февраль', completions: 15, newEnrollments: 22 },
    { month: 'Март', completions: 18, newEnrollments: 25 }
  ]
};

// Отзывы о курсах
export const mockCourseReviews = [
  {
    id: 'review1',
    courseId: 'course1',
    agentId: 'agent1',
    rating: 5,
    comment: 'Отличный базовый курс, много практических примеров',
    date: '2024-02-15'
  },
  {
    id: 'review2',
    courseId: 'course2',
    agentId: 'agent2',
    rating: 4,
    comment: 'Полезные техники продаж, но хотелось бы больше практики',
    date: '2024-03-01'
  }
];

// Достижения
export const mockAchievements = [
  {
    id: 'ach1',
    title: 'Быстрый старт',
    description: 'Завершите первый курс менее чем за 3 дня',
    icon: '🚀',
    points: 100
  },
  {
    id: 'ach2',
    title: 'Отличник',
    description: 'Получите 95+ баллов в любом курсе',
    icon: '🏆',
    points: 150
  }
];

// Прогресс по достижениям агентов
export const mockAgentAchievements = {
  'agent1': ['ach1', 'ach2'],
  'agent2': ['ach1']
}; 