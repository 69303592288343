import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Tabs,
  Tab,
  Stack,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  TablePagination,
  TableSortLabel,
  Tooltip
} from '@mui/material';
import {
  GridView as GridViewIcon,
  List as ListIcon,
  ViewQuilt as FloorPlansIcon,
  ViewInAr as WalkthroughIcon
} from '@mui/icons-material';
import { useUnits } from '../../hooks/useUnits';
import type { UnitFilters, Unit, PriceRange, AreaRange } from '../../types/unit';
import { PRICE_RANGES, AREA_RANGES } from '../../types/unit';
import { styled } from '@mui/material/styles';
import FloorPlans from './components/FloorPlans';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`units-tabpanel-${index}`}
      aria-labelledby={`units-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

type UnitStatusType = 'available' | 'reserved' | 'sold' | 'unavailable';

const UnitStatus = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'status'
})<{ status: UnitStatusType }>(({ theme, status }) => {
  const colors: Record<UnitStatusType, string> = {
    available: theme.palette.success.main,
    reserved: theme.palette.warning.main,
    sold: theme.palette.error.main,
    unavailable: theme.palette.grey[500]
  };

  return {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: colors[status],
    marginRight: theme.spacing(1)
  };
});

const getStatusStyles = (status: UnitStatusType) => {
  const styles: Record<UnitStatusType, any> = {
    available: {
      backgroundColor: 'rgba(84, 214, 44, 0.08)',
      borderColor: 'success.main',
      '&:hover': {
        backgroundColor: 'rgba(84, 214, 44, 0.16)',
        boxShadow: '0 0 0 1px #54D62C'
      }
    },
    reserved: {
      backgroundColor: 'rgba(255, 171, 0, 0.08)',
      borderColor: 'warning.main',
      '&:hover': {
        backgroundColor: 'rgba(255, 171, 0, 0.16)',
        boxShadow: '0 0 0 1px #FFAB00'
      }
    },
    sold: {
      backgroundColor: 'rgba(255, 86, 48, 0.08)',
      borderColor: 'error.main',
      '&:hover': {
        backgroundColor: 'rgba(255, 86, 48, 0.16)',
        boxShadow: '0 0 0 1px #FF5630'
      }
    },
    unavailable: {
      backgroundColor: 'rgba(145, 158, 171, 0.08)',
      borderColor: 'grey.500',
      '&:hover': {
        backgroundColor: 'rgba(145, 158, 171, 0.16)',
        boxShadow: '0 0 0 1px #919EAB'
      }
    }
  };
  return styles[status];
};

interface HeadCell {
  id: keyof Unit;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'number', label: 'Unit №', numeric: false },
  { id: 'floor', label: 'Floor', numeric: true },
  { id: 'status', label: 'Status', numeric: false },
  { id: 'area', label: 'Area', numeric: true },
  { id: 'bedrooms', label: 'Bedrooms', numeric: true },
  { id: 'layout', label: 'Layout', numeric: false },
  { id: 'windowView', label: 'Window View', numeric: false },
  { id: 'price', label: 'Price', numeric: true },
  { id: 'pricePerSqm', label: 'Price/m²', numeric: true },
  { id: 'completion', label: 'Completion', numeric: false }
];

const UnitsList = ({ units }: { units: Unit[] }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState<keyof Unit>('number');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');

  const handleSort = (property: keyof Unit) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedUnits = [...units].sort((a, b) => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return order === 'asc' 
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    }

    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return order === 'asc'
        ? aValue - bValue
        : bValue - aValue;
    }

    return 0;
  });

  const paginatedUnits = sortedUnits.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small">
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? 'right' : 'left'}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={() => handleSort(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedUnits.map((unit) => (
              <TableRow
                key={unit.id}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  ...getStatusStyles(unit.status),
                  '&:hover': {
                    cursor: 'pointer',
                    ...getStatusStyles(unit.status)['&:hover']
                  }
                }}
              >
                <TableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <UnitStatus status={unit.status} />
                    №{unit.number}
                  </Box>
                </TableCell>
                <TableCell>{unit.floor}</TableCell>
                <TableCell>
                  <Chip
                    label={unit.status}
                    size="small"
                    color={
                      unit.status === 'available' ? 'success' :
                      unit.status === 'reserved' ? 'warning' :
                      unit.status === 'sold' ? 'error' : 'default'
                    }
                    variant="outlined"
                  />
                </TableCell>
                <TableCell>{unit.area} m²</TableCell>
                <TableCell>{unit.bedrooms}</TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {unit.layout.replace('-', ' ')}
                  </Typography>
                </TableCell>
                <TableCell>{unit.windowView}</TableCell>
                <TableCell>
                  <Typography
                    variant="body2"
                    sx={{
                      color: unit.status === 'available' ? 'success.main' :
                             unit.status === 'reserved' ? 'warning.main' :
                             unit.status === 'sold' ? 'error.main' : 'text.secondary',
                      fontWeight: 600
                    }}
                  >
                    {new Intl.NumberFormat('th-TH', {
                      style: 'currency',
                      currency: 'THB',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    }).format(unit.price)}
                  </Typography>
                </TableCell>
                <TableCell>
                  {new Intl.NumberFormat('th-TH', {
                    style: 'currency',
                    currency: 'THB',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  }).format(unit.pricePerSqm)}
                </TableCell>
                <TableCell>{unit.completion}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        count={units.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(_, newPage) => setPage(newPage)}
        onRowsPerPageChange={(e) => {
          setRowsPerPage(parseInt(e.target.value, 10));
          setPage(0);
        }}
      />
    </>
  );
};

const UnitsGrid = () => {
  const [view, setView] = useState(0);
  const [filters, setFilters] = useState<UnitFilters>({
    building: '',
    availability: 'all',
    bedrooms: 'all',
    priceRange: 'all',
    floorArea: 'all',
    windowView: 'all',
    layout: 'all',
    furnished: 'all',
    pricePerSqm: 'all',
    completion: 'all',
    floor: 'all'
  });

  const { units, loading, error } = useUnits(filters);

  const handleUnitClick = (unit: Unit) => {
    console.log('Clicked unit:', unit);
    // Здесь можно добавить открытие модального окна с деталями юнита
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 4, color: 'error.main' }}>
        <Typography>{error}</Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth="xl">
      <Box sx={{ py: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h5">
            VIP Space Odyssey
          </Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mr: 2 }}>
              <UnitStatus status="available" />
              <Typography variant="caption">Available</Typography>
              <UnitStatus status="reserved" />
              <Typography variant="caption">Reserved</Typography>
              <UnitStatus status="sold" />
              <Typography variant="caption">Sold</Typography>
              <UnitStatus status="unavailable" />
              <Typography variant="caption">Unavailable</Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
          <Tabs value={view} onChange={(_, newValue) => setView(newValue)}>
            <Tab icon={<GridViewIcon />} label="Units Grid" />
            <Tab icon={<ListIcon />} label="Units List" />
            <Tab icon={<FloorPlansIcon />} label="Floor Plans" />
            <Tab icon={<WalkthroughIcon />} label="3D Walkthrough" />
          </Tabs>
        </Box>

        <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
          <FormControl size="small" sx={{ minWidth: 120 }}>
            <InputLabel>Building</InputLabel>
            <Select
              value={filters.building}
              label="Building"
              onChange={(e) => setFilters({ ...filters, building: e.target.value })}
            >
              <MenuItem value="">All Buildings</MenuItem>
              <MenuItem value="1">Building №1</MenuItem>
              <MenuItem value="2">Building №2</MenuItem>
              <MenuItem value="3">Building №3</MenuItem>
            </Select>
          </FormControl>

          <FormControl size="small" sx={{ minWidth: 120 }}>
            <InputLabel>Availability</InputLabel>
            <Select
              value={filters.availability}
              label="Availability"
              onChange={(e) => setFilters({ ...filters, availability: e.target.value })}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="available">Available</MenuItem>
              <MenuItem value="reserved">Reserved</MenuItem>
              <MenuItem value="sold">Sold</MenuItem>
              <MenuItem value="unavailable">Unavailable</MenuItem>
            </Select>
          </FormControl>

          <FormControl size="small" sx={{ minWidth: 120 }}>
            <InputLabel>Bedrooms</InputLabel>
            <Select
              value={filters.bedrooms}
              label="Bedrooms"
              onChange={(e) => setFilters({ ...filters, bedrooms: e.target.value })}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="0">Studio</MenuItem>
              <MenuItem value="1">1 Bedroom</MenuItem>
              <MenuItem value="2">2 Bedrooms</MenuItem>
              <MenuItem value="3">3 Bedrooms</MenuItem>
            </Select>
          </FormControl>

          <FormControl size="small" sx={{ minWidth: 120 }}>
            <InputLabel>Price Range</InputLabel>
            <Select
              value={filters.priceRange}
              label="Price Range"
              onChange={(e) => setFilters({ ...filters, priceRange: e.target.value })}
            >
              <MenuItem value="all">All</MenuItem>
              {PRICE_RANGES.map((range: PriceRange, index: number) => (
                <MenuItem key={index} value={index}>{range.label}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl size="small" sx={{ minWidth: 120 }}>
            <InputLabel>Floor Area</InputLabel>
            <Select
              value={filters.floorArea}
              label="Floor Area"
              onChange={(e) => setFilters({ ...filters, floorArea: e.target.value })}
            >
              <MenuItem value="all">All</MenuItem>
              {AREA_RANGES.map((range: AreaRange, index: number) => (
                <MenuItem key={index} value={index}>{range.label}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl size="small" sx={{ minWidth: 120 }}>
            <InputLabel>Window View</InputLabel>
            <Select
              value={filters.windowView}
              label="Window View"
              onChange={(e) => setFilters({ ...filters, windowView: e.target.value })}
            >
              <MenuItem value="all">All Views</MenuItem>
              <MenuItem value="sea">Sea View</MenuItem>
              <MenuItem value="mountain">Mountain View</MenuItem>
              <MenuItem value="garden">Garden View</MenuItem>
              <MenuItem value="city">City View</MenuItem>
            </Select>
          </FormControl>

          <FormControl size="small" sx={{ minWidth: 120 }}>
            <InputLabel>Layout</InputLabel>
            <Select
              value={filters.layout}
              label="Layout"
              onChange={(e) => setFilters({ ...filters, layout: e.target.value })}
            >
              <MenuItem value="all">All Layouts</MenuItem>
              <MenuItem value="studio">Studio</MenuItem>
              <MenuItem value="1-bedroom">1 Bedroom</MenuItem>
              <MenuItem value="2-bedroom">2 Bedrooms</MenuItem>
              <MenuItem value="3-bedroom">3 Bedrooms</MenuItem>
              <MenuItem value="penthouse">Penthouse</MenuItem>
            </Select>
          </FormControl>
        </Stack>

        <TabPanel value={view} index={0}>
          <Grid container spacing={2}>
            {Array.from(new Set(units.map(unit => unit.floor))).sort().map(floor => (
              <Grid item xs={12} key={floor}>
                <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
                  {floor} floor
                </Typography>
                <Grid container spacing={2}>
                  {units
                    .filter(unit => unit.floor === floor)
                    .map(unit => (
                      <Grid item key={unit.id} xs={12} sm={6} md={4} lg={3}>
                        <Box 
                          sx={{ 
                            p: 2, 
                            border: '1px solid',
                            borderRadius: 1,
                            transition: 'all 0.2s ease-in-out',
                            cursor: 'pointer',
                            ...getStatusStyles(unit.status)
                          }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                            <UnitStatus status={unit.status} />
                            <Typography variant="subtitle2">
                              №{unit.number}
                            </Typography>
                          </Box>
                          <Stack spacing={0.5}>
                            <Typography variant="body2" color="text.secondary">
                              {unit.area} m²
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {unit.bedrooms} bedroom
                            </Typography>
                            <Typography 
                              variant="body2" 
                              sx={{ 
                                color: unit.status === 'available' ? 'success.main' : 
                                       unit.status === 'reserved' ? 'warning.main' :
                                       unit.status === 'sold' ? 'error.main' : 'text.secondary',
                                fontWeight: 600
                              }}
                            >
                              {new Intl.NumberFormat('th-TH', {
                                style: 'currency',
                                currency: 'THB',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0
                              }).format(unit.price)}
                            </Typography>
                          </Stack>
                        </Box>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </TabPanel>

        <TabPanel value={view} index={1}>
          <UnitsList units={units} />
        </TabPanel>

        <TabPanel value={view} index={2}>
          <FloorPlans units={units} onUnitClick={handleUnitClick} />
        </TabPanel>

        <TabPanel value={view} index={3}>
          <Typography>3D Walkthrough Content</Typography>
        </TabPanel>
      </Box>
    </Container>
  );
};

export default UnitsGrid; 