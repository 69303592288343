// Определяем типы для макетов
export type UnitLayout = 'studio' | '1-bedroom' | '2-bedroom' | '3-bedroom' | 'penthouse';

export interface Room {
  area: number;
  label: string;
}

export interface LayoutInfo {
  area: [number, number];
  bedrooms: number;
  path: string;
  rooms: Record<string, Room>;
}

export interface Unit {
  id: string;
  number: string;
  floor: number;
  building: string;
  status: 'available' | 'reserved' | 'sold' | 'unavailable';
  area: number;
  bedrooms: number;
  price: number;
  windowView: 'sea' | 'mountain' | 'garden' | 'city';
  pricePerSqm: number;
  layout: UnitLayout;
  completion: string;
  furnished: boolean;
  path: string;
}

export interface UnitFilters {
  building: string;
  availability: string;
  bedrooms: string;
  priceRange: string;
  floorArea: string;
  windowView: string;
  layout: string;
  furnished: string;
  pricePerSqm: string;
  completion: string;
  floor: string;
}

export interface PriceRange {
  min: number;
  max: number;
  label: string;
}

export interface AreaRange {
  min: number;
  max: number;
  label: string;
}

export const PRICE_RANGES: PriceRange[] = [
  { min: 0, max: 50000000, label: 'Up to ฿50M' },
  { min: 50000000, max: 100000000, label: '฿50M - ฿100M' },
  { min: 100000000, max: 150000000, label: '฿100M - ฿150M' },
  { min: 150000000, max: 200000000, label: '฿150M - ฿200M' },
  { min: 200000000, max: Infinity, label: 'Above ฿200M' }
];

export const AREA_RANGES: AreaRange[] = [
  { min: 0, max: 50, label: 'Up to 50 m²' },
  { min: 50, max: 75, label: '50-75 m²' },
  { min: 75, max: 100, label: '75-100 m²' },
  { min: 100, max: 150, label: '100-150 m²' },
  { min: 150, max: Infinity, label: 'Above 150 m²' }
]; 