import { RouteObject } from 'react-router-dom';
import Properties from './pages/Properties/Properties';
import PropertyDetails from './pages/Properties/PropertyDetails';
import Dashboard from './pages/Dashboard/Dashboard';
import CompanyPage from './pages/CompanyPage/CompanyPage';
import Calendar from './pages/Calendar/Calendar';
import Messages from './pages/Messages/Messages';
import ClientRequests from './pages/ClientRequests/ClientRequests';
import UnitsGrid from './pages/Units/UnitsGrid';

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <Dashboard />
  },
  {
    path: '/company',
    element: <CompanyPage />
  },
  {
    path: '/properties',
    element: <Properties />
  },
  {
    path: '/properties/:id',
    element: <PropertyDetails />
  },
  {
    path: '/units',
    element: <UnitsGrid />
  },
  {
    path: '/calendar',
    element: <Calendar />
  },
  {
    path: '/messages',
    element: <Messages />
  },
  {
    path: '/requests',
    element: <ClientRequests />
  }
]; 