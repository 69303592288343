import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Stack,
  Tooltip,
  IconButton,
  Button
} from '@mui/material';
import {
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  FullscreenExit as ResetIcon,
  Lock as LockIcon
} from '@mui/icons-material';
import { Unit } from '../../../types/unit';

interface FloorPlansProps {
  units: Unit[];
  onUnitClick: (unit: Unit) => void;
}

export const FloorPlans: React.FC<FloorPlansProps> = ({ units, onUnitClick }) => {
  const [selectedFloor, setSelectedFloor] = useState<number>(9);
  const [zoom, setZoom] = useState(1);
  
  const floors = Array.from(new Set(units.map(unit => unit.floor))).sort((a, b) => b - a);
  const floorUnits = units.filter(unit => unit.floor === selectedFloor);

  const handleZoomIn = () => setZoom(prev => Math.min(prev + 0.2, 2));
  const handleZoomOut = () => setZoom(prev => Math.max(prev - 0.2, 0.5));
  const handleReset = () => setZoom(1);

  const getUnitColor = (status: Unit['status']) => {
    const colors = {
      available: {
        fill: 'rgba(84, 214, 44, 0.16)',
        stroke: '#54D62C'
      },
      reserved: {
        fill: 'rgba(255, 171, 0, 0.16)',
        stroke: '#FFAB00'
      },
      sold: {
        fill: 'rgba(255, 86, 48, 0.16)',
        stroke: '#FF5630'
      },
      unavailable: {
        fill: 'rgba(145, 158, 171, 0.16)',
        stroke: '#919EAB'
      }
    };
    return colors[status];
  };

  const renderUnit = (unit: Unit, x: number, y: number) => {
    const colors = getUnitColor(unit.status);
    const scale = 0.8; // Масштаб для юнита

    // Базовая планировка для 1-bedroom квартиры
    const basePath = `
      M ${x},${y} 
      h 120 v 180 h -120 z
      M ${x},${y + 90} h 120
      M ${x + 60},${y} v 180
      M ${x + 10},${y + 10} h 40 v 70 h -40 z
      M ${x + 70},${y + 10} h 40 v 70 h -40 z
      M ${x + 10},${y + 100} h 100 v 70 h -100 z
    `;

    return (
      <g key={unit.id}>
        <path
          d={basePath}
          fill={colors.fill}
          stroke={colors.stroke}
          strokeWidth="1"
          onClick={() => onUnitClick(unit)}
          style={{ cursor: 'pointer' }}
        />
        <text
          x={x + 60}
          y={y + 90}
          textAnchor="middle"
          fill="#333"
          fontSize="12"
        >
          №{unit.number}
        </text>
        <text
          x={x + 60}
          y={y + 105}
          textAnchor="middle"
          fill="#666"
          fontSize="10"
        >
          {unit.area} m²
        </text>
      </g>
    );
  };

  const renderFloor = (floorUnits: Unit[]) => {
    const UNITS_PER_ROW = 6;
    const UNIT_WIDTH = 140;
    const UNIT_HEIGHT = 200;
    const MARGIN = 20;

    return floorUnits.map((unit, index) => {
      const row = Math.floor(index / UNITS_PER_ROW);
      const col = index % UNITS_PER_ROW;
      const x = MARGIN + col * (UNIT_WIDTH + MARGIN);
      const y = MARGIN + row * (UNIT_HEIGHT + MARGIN);

      return (
        <Tooltip
          key={unit.id}
          title={
            <Box sx={{ p: 1 }}>
              <Typography variant="subtitle2">Unit №{unit.number}</Typography>
              <Typography variant="body2">{unit.area} m²</Typography>
              <Typography variant="body2">{unit.bedrooms} bedrooms</Typography>
              <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
                {unit.status}
              </Typography>
              <Typography variant="body2">
                {new Intl.NumberFormat('th-TH', {
                  style: 'currency',
                  currency: 'THB',
                  minimumFractionDigits: 0
                }).format(unit.price)}
              </Typography>
            </Box>
          }
        >
          {renderUnit(unit, x, y)}
        </Tooltip>
      );
    });
  };

  return (
    <Box>
      <Stack direction="row" spacing={2} alignItems="flex-start" sx={{ mb: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {floors.map(floor => (
            <Button
              key={floor}
              variant={selectedFloor === floor ? "contained" : "outlined"}
              size="small"
              onClick={() => setSelectedFloor(floor)}
              sx={{ 
                minWidth: '60px',
                justifyContent: 'flex-start',
                px: 1
              }}
            >
              {floor} этаж
            </Button>
          ))}
        </Box>

        <Paper 
          sx={{ 
            position: 'relative',
            height: 800,
            width: '100%',
            overflow: 'auto',
            bgcolor: '#fff'
          }}
        >
          <Box sx={{ position: 'absolute', right: 16, top: 16, zIndex: 1 }}>
            <IconButton onClick={handleZoomOut}>
              <ZoomOutIcon />
            </IconButton>
            <IconButton onClick={handleReset}>
              <ResetIcon />
            </IconButton>
            <IconButton onClick={handleZoomIn}>
              <ZoomInIcon />
            </IconButton>
          </Box>

          <Box
            sx={{
              transform: `scale(${zoom})`,
              transformOrigin: '0 0',
              transition: 'transform 0.2s ease-in-out',
              p: 2
            }}
          >
            <svg 
              width="1000" 
              height="600" 
              viewBox="0 0 1000 600"
              style={{ 
                background: '#f5f5f5',
                border: '1px solid #e0e0e0'
              }}
            >
              {/* Коридор */}
              <path
                d="M 50,250 H 950 V 350 H 50 Z"
                fill="#e0e0e0"
                stroke="#ccc"
              />
              
              {/* Юниты */}
              {renderFloor(floorUnits)}
            </svg>
          </Box>
        </Paper>
      </Stack>
    </Box>
  );
};

export default FloorPlans;