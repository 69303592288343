import { useState, useEffect } from 'react';
import { Unit, UnitFilters, PRICE_RANGES, AREA_RANGES } from '../types/unit';
import { mockUnits } from '../utils/mockDataGenerator';

export const useUnits = (filters: UnitFilters) => {
  const [units, setUnits] = useState<Unit[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchUnits = async () => {
      try {
        await new Promise(resolve => setTimeout(resolve, 500));
        
        let filteredUnits = [...mockUnits];
        
        // Применяем фильтры
        if (filters.building) {
          filteredUnits = filteredUnits.filter(unit => unit.building === filters.building);
        }
        
        if (filters.availability !== 'all') {
          filteredUnits = filteredUnits.filter(unit => unit.status === filters.availability);
        }

        if (filters.bedrooms !== 'all') {
          filteredUnits = filteredUnits.filter(unit => unit.bedrooms === Number(filters.bedrooms));
        }

        if (filters.windowView !== 'all') {
          filteredUnits = filteredUnits.filter(unit => unit.windowView === filters.windowView);
        }

        if (filters.layout !== 'all') {
          filteredUnits = filteredUnits.filter(unit => unit.layout === filters.layout);
        }

        if (filters.furnished !== 'all') {
          filteredUnits = filteredUnits.filter(unit => 
            filters.furnished === 'yes' ? unit.furnished : !unit.furnished
          );
        }

        if (filters.priceRange !== 'all') {
          const range = PRICE_RANGES[Number(filters.priceRange)];
          filteredUnits = filteredUnits.filter(unit => 
            unit.price >= range.min && unit.price <= range.max
          );
        }

        if (filters.floorArea !== 'all') {
          const range = AREA_RANGES[Number(filters.floorArea)];
          filteredUnits = filteredUnits.filter(unit => 
            unit.area >= range.min && unit.area <= range.max
          );
        }

        if (filters.floor !== 'all') {
          filteredUnits = filteredUnits.filter(unit => String(unit.floor) === filters.floor);
        }

        setUnits(filteredUnits);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch units');
        setLoading(false);
      }
    };

    fetchUnits();
  }, [filters]);

  return { units, loading, error };
}; 