import React, { useState, useEffect } from 'react';
import { 
  Typography, 
  Box, 
  Grid, 
  Paper, 
  IconButton,
  Card,
  CardContent,
  LinearProgress,
  Chip,
  Avatar,
  Button,
  Tooltip,
  Collapse,
  Divider,
  Stack,
  CircularProgress,
  Badge,
  ListItemIcon,
  ListItemText,
  List,
  ListItem,
  Rating
} from '@mui/material';
import { 
  FileDownloadOutlined,
  School as SchoolIcon,
  Person as PersonIcon,
  Assignment as AssignmentIcon,
  EmojiEvents as TrophyIcon,
  PlayArrow as StartIcon,
  Assessment as StatusIcon,
  Lock as LockIcon,
  ExpandMore as ExpandMoreIcon,
  Timer as TimerIcon,
  Star as StarIcon,
  CheckCircle as CheckCircleIcon,
  Info as InfoIcon,
  MenuBook as MenuBookIcon,
  Group as GroupIcon,
  Timeline as TimelineIcon,
  Whatshot as FireIcon,
  LocalLibrary as LibraryIcon,
  ArrowForward as ArrowForwardIcon,
  Leaderboard as LeaderboardIcon
} from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { mockTrainingModules, mockAgents, mockCourseModules, mockAchievements, mockAgentAchievements } from '../../data/mockTrainingData';
import { TrainingModule, Agent, CourseModule, Achievement } from '../../types/training';
import { tableStyles } from '../../styles/tableStyles';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

interface StatCardProps {
  title: string;
  value: number | string;
  icon: React.ReactNode;
  change?: number;
  color?: string;
}

const StatCard: React.FC<StatCardProps> = ({ title, value, icon, change, color = 'primary.main' }) => (
  <Card sx={{ height: '100%' }}>
    <CardContent>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Avatar sx={{ bgcolor: color, mr: 2 }}>{icon}</Avatar>
        <Typography variant="h6" color="text.secondary">
          {title}
        </Typography>
      </Box>
      <Typography variant="h4" sx={{ mb: 1 }}>
        {value}
      </Typography>
      {change !== undefined && (
        <Typography 
          variant="body2" 
          color={change >= 0 ? 'success.main' : 'error.main'}
        >
          {change >= 0 ? '+' : ''}{change}% с прошлого месяца
        </Typography>
      )}
    </CardContent>
  </Card>
);

function isCourseModule(module: TrainingModule): module is CourseModule {
  return 'level' in module && 'duration' in module && 'requiredScore' in module;
}

const hasPrerequisites = (module: TrainingModule): boolean => {
  if (!isCourseModule(module)) return false;
  return Boolean(module.prerequisites && module.prerequisites.length > 0);
};

interface CourseCardProps {
  course: CourseModule;
  completedByAgent?: boolean;
}

const CourseCard: React.FC<CourseCardProps> = ({ course, completedByAgent }) => {
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleStart = async () => {
    setLoading(true);
    try {
      await new Promise(resolve => setTimeout(resolve, 1000));
      navigate(`/agent-training/course/${course.id}`);
    } catch (error) {
      console.error('Error starting course:', error);
    } finally {
      setLoading(false);
    }
  };

  const getLevelColor = (level: string) => {
    switch (level) {
      case 'Начальный': return 'success';
      case 'Средний': return 'warning';
      case 'Продвинутый': return 'error';
      default: return 'default';
    }
  };

  const progressData = [
    { name: 'Completed', value: course.progress?.completed || 0 },
    { name: 'Remaining', value: (course.progress?.enrolled || 0) - (course.progress?.completed || 0) }
  ];

  const COLORS = ['#00C49F', '#FFBB28'];

  return (
    <Card 
      sx={{ 
        mb: 2,
        transition: 'all 0.3s ease',
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: 4
        }
      }}
    >
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
          <Box>
            <Typography variant="h6" gutterBottom>
              {course.title}
              {completedByAgent && (
                <CheckCircleIcon sx={{ ml: 1, color: 'success.main' }} />
              )}
            </Typography>
            <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
              <Chip 
                icon={<StarIcon />}
                label={course.level}
                size="small"
                color={getLevelColor(course.level)}
              />
              <Chip 
                icon={<TimerIcon />}
                label={course.duration}
                size="small"
                variant="outlined"
              />
              <Chip 
                icon={<MenuBookIcon />}
                label={`${course.lessons.length} уроков`}
                size="small"
                variant="outlined"
              />
            </Stack>
          </Box>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button
              variant="contained"
              startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <StartIcon />}
              disabled={loading || (course.prerequisites?.length > 0 && !completedByAgent)}
              onClick={handleStart}
              color="primary"
            >
              Начать
            </Button>
            <IconButton 
              onClick={() => setExpanded(!expanded)}
              sx={{ 
                transform: expanded ? 'rotate(180deg)' : 'none',
                transition: 'transform 0.3s'
              }}
            >
              <ExpandMoreIcon />
            </IconButton>
          </Box>
        </Box>

        <Typography variant="body2" color="text.secondary">
          {course.description}
        </Typography>

        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
          <ResponsiveContainer width={100} height={100}>
            <PieChart>
              <Pie
                data={progressData}
                cx="50%"
                cy="50%"
                innerRadius={25}
                outerRadius={40}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
              >
                {progressData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          <Box>
            <Typography variant="body2" color="text.secondary">
              Прогресс курса
            </Typography>
            <Typography variant="h6">
              {Math.round((course.progress?.completed || 0) / (course.progress?.enrolled || 1) * 100)}%
            </Typography>
          </Box>
        </Box>

        <Collapse in={expanded}>
          <Divider sx={{ my: 2 }} />
          
          <List dense>
            {course.lessons.map((lesson, index) => (
              <ListItem key={lesson.id}>
                <ListItemIcon>
                  <Badge 
                    badgeContent={lesson.test.questions.length} 
                    color="primary"
                    sx={{ '& .MuiBadge-badge': { fontSize: '0.8rem' } }}
                  >
                    <AssignmentIcon color="action" />
                  </Badge>
                </ListItemIcon>
                <ListItemText
                  primary={lesson.title}
                  secondary={`Тест: ${lesson.test.timeLimit / 60} минут`}
                />
                <Chip 
                  size="small"
                  label={`${lesson.test.questions.reduce((acc, q) => acc + q.points, 0)} баллов`}
                  color="secondary"
                  variant="outlined"
                />
              </ListItem>
            ))}
          </List>

          {course.prerequisites?.length > 0 && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2" color="warning.main" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <LockIcon fontSize="small" />
                Требуется пройти:
              </Typography>
              <List dense>
                {course.prerequisites.map(prereq => (
                  <ListItem key={prereq}>
                    <ListItemIcon>
                      <InfoIcon color="action" fontSize="small" />
                    </ListItemIcon>
                    <ListItemText 
                      primary={mockCourseModules.find(c => c.id === prereq)?.title || prereq}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}

          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Stack direction="row" spacing={2}>
              <Tooltip title="Общий балл курса">
                <Chip
                  icon={<TimelineIcon />}
                  label={`${course.totalScore} баллов`}
                  color="primary"
                  variant="outlined"
                />
              </Tooltip>
              <Tooltip title="Проходной балл">
                <Chip
                  icon={<StarIcon />}
                  label={`Минимум ${course.requiredScore}%`}
                  color="warning"
                  variant="outlined"
                />
              </Tooltip>
            </Stack>
            <Button
              variant="outlined"
              startIcon={<GroupIcon />}
              size="small"
            >
              Статистика прохождения
            </Button>
          </Box>
        </Collapse>
      </CardContent>
    </Card>
  );
};

const AchievementCard: React.FC<{ achievement: Achievement; unlocked: boolean }> = ({ achievement, unlocked }) => (
  <Card sx={{ mb: 2, opacity: unlocked ? 1 : 0.6 }}>
    <CardContent>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <Avatar sx={{ bgcolor: unlocked ? 'success.main' : 'grey.500', mr: 2 }}>
          {achievement.icon}
        </Avatar>
        <Typography variant="h6">{achievement.title}</Typography>
      </Box>
      <Typography variant="body2" color="text.secondary">
        {achievement.description}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
        <Chip 
          icon={<StarIcon />}
          label={`${achievement.points} очков`}
          color={unlocked ? "primary" : "default"}
          variant="outlined"
        />
        {unlocked ? (
          <CheckCircleIcon color="success" />
        ) : (
          <LockIcon color="action" />
        )}
      </Box>
    </CardContent>
  </Card>
);

const TrainingDashboard: React.FC = () => {
  const [modules] = useState<TrainingModule[]>(mockTrainingModules);
  const [agents] = useState<Agent[]>(mockAgents);
  const [courses] = useState<CourseModule[]>(mockCourseModules);
  const [achievements] = useState<Achievement[]>(mockAchievements);
  const [currentAgent] = useState<Agent>(agents[0]); // Предполагаем, что это текущий агент

  const stats = {
    totalAgents: {
      value: agents.length,
      icon: <PersonIcon />,
      change: 5,
    },
    activeModules: {
      value: modules.length + courses.length,
      icon: <SchoolIcon />,
      change: 10,
    },
    completedTests: {
      value: 48,
      icon: <AssignmentIcon />,
      change: 15,
    },
    certifications: {
      value: 32,
      icon: <TrophyIcon />,
      change: 8,
    },
  };

  const agentAchievements = mockAgentAchievements[currentAgent.id as keyof typeof mockAgentAchievements] || [];

  return (
    <Box sx={{ p: 3 }}>
      {/* Header */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Box>
          <Typography variant="h5">Training Dashboard</Typography>
          <Typography variant="subtitle1" color="text.secondary">
            Управление обучением агентов
          </Typography>
        </Box>
        <IconButton 
          sx={{ 
            border: '1px solid', 
            borderColor: 'grey.200',
            borderRadius: 1,
            px: 2
          }}
        >
          <FileDownloadOutlined sx={{ mr: 1 }} />
          <Typography variant="button">Export</Typography>
        </IconButton>
      </Box>

      {/* Stats Grid */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        {Object.entries(stats).map(([key, data]) => (
          <Grid item xs={12} sm={6} md={3} key={key}>
            <StatCard title={key} {...data} />
          </Grid>
        ))}
      </Grid>

      {/* Content Grid */}
      <Grid container spacing={3}>
        {/* Active Courses */}
        <Grid item xs={12} md={8}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <SchoolIcon color="primary" />
              Активные курсы
            </Typography>
            
            {courses.map((course) => (
              <CourseCard
                key={course.id}
                course={course}
                completedByAgent={currentAgent.completedModules.includes(course.id)}
              />
            ))}
          </Paper>
        </Grid>

        {/* Agent Progress and Achievements */}
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <PersonIcon color="primary" />
              Ваш прогресс
            </Typography>
            
            <Box sx={{ mb: 3 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Avatar sx={{ mr: 2 }}>{currentAgent.name[0]}</Avatar>
                <Box>
                  <Typography variant="subtitle2">{currentAgent.name}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {currentAgent.email}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <LinearProgress 
                  variant="determinate" 
                  value={(currentAgent.completedModules.length / courses.length) * 100}
                  sx={{ flexGrow: 1 }}
                />
                <Typography variant="body2" color="text.secondary">
                  {Math.round((currentAgent.completedModules.length / courses.length) * 100)}%
                </Typography>
              </Box>
            </Box>

            <Divider sx={{ my: 2 }} />

            <Typography variant="subtitle1" gutterBottom>
              Текущий курс
            </Typography>
            {currentAgent.progress?.currentCourse && (
              <Card sx={{ mb: 2 }}>
                <CardContent>
                  <Typography variant="h6">
                    {courses.find(c => c.id === currentAgent.progress?.currentCourse)?.title}
                  </Typography>
                  <LinearProgress 
                    variant="determinate" 
                    value={50} // Здесь нужно рассчитать реальный прогресс
                    sx={{ mt: 1 }}
                  />
                </CardContent>
              </Card>
            )}

            <Divider sx={{ my: 2 }} />

            <Typography variant="subtitle1" gutterBottom>
              Последняя активность
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {currentAgent.progress?.lastActivity}
            </Typography>

            <Divider sx={{ my: 2 }} />

            <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <TrophyIcon color="primary" fontSize="small" />
              Достижения
            </Typography>
            {achievements.map(achievement => (
              <AchievementCard 
                key={achievement.id} 
                achievement={achievement} 
                unlocked={agentAchievements.includes(achievement.id)} 
              />
            ))}
          </Paper>
        </Grid>
      </Grid>

      {/* Learning Path */}
      <Paper sx={{ p: 3, mt: 3 }}>
        <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <TimelineIcon color="primary" />
          Путь обучения
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', overflowX: 'auto', py: 2 }}>
          {courses.map((course, index) => (
            <React.Fragment key={course.id}>
              <Card sx={{ minWidth: 200, mr: 2 }}>
                <CardContent>
                  <Typography variant="subtitle1">{course.title}</Typography>
                  <Rating 
                    name={`course-${course.id}-rating`} 
                    value={currentAgent.completedModules.includes(course.id) ? 5 : 0} 
                    readOnly 
                  />
                </CardContent>
              </Card>
              {index < courses.length - 1 && (
                <ArrowForwardIcon color="action" sx={{ mr: 2 }} />
              )}
            </React.Fragment>
          ))}
        </Box>
      </Paper>

      {/* Leaderboard */}
      <Paper sx={{ p: 3, mt: 3 }}>
        <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <LeaderboardIcon color="primary" />
          Таблица лидеров
        </Typography>
        <List>
          {agents.sort((a, b) => b.completedModules.length - a.completedModules.length).map((agent, index) => (
            <ListItem key={agent.id}>
              <ListItemIcon>
                <Avatar sx={{ bgcolor: index < 3 ? 'warning.main' : 'grey.400' }}>
                  {index + 1}
                </Avatar>
              </ListItemIcon>
              <ListItemText 
                primary={agent.name} 
                secondary={`${agent.completedModules.length} завершенных курсов`} 
              />
              <Chip 
                icon={<StarIcon />}
                label={`${agent.completedModules.length * 100} очков`}
                color={index < 3 ? "primary" : "default"}
              />
            </ListItem>
          ))}
        </List>
      </Paper>
    </Box>
  );
};

export default TrainingDashboard; 