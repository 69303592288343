import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  CircularProgress,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Button,
  Grid,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip,
  Divider
} from '@mui/material';
import {
  PlayCircleOutline as VideoIcon,
  PictureAsPdf as PdfIcon,
  Slideshow as PresentationIcon,
  Assignment as TestIcon,
  Timer as TimerIcon,
  CheckCircle as DoneIcon,
} from '@mui/icons-material';
import { useCourses } from '../../hooks/useCourses';
import { mockMaterials } from '../../data/materials';
import { Lesson, Material } from '../../types/training';

const CoursePage: React.FC = () => {
  const { courseId } = useParams<{ courseId: string }>();
  const navigate = useNavigate();
  const { currentCourse, loading, error, startCourse } = useCourses();
  const [activeLesson, setActiveLesson] = useState<number>(0);
  const [completedLessons, setCompletedLessons] = useState<Set<string>>(new Set());

  useEffect(() => {
    if (courseId) {
      startCourse(courseId);
    }
  }, [courseId, startCourse]);

  const handleStartTest = (lessonId: string) => {
    navigate(`/agent-training/test/${courseId}/${lessonId}`);
  };

  const handleMaterialClick = (material: Material) => {
    // В реальном приложении здесь будет логика открытия материала
    console.log('Opening material:', material);
  };

  const getMaterialIcon = (type: Material['type']) => {
    switch (type) {
      case 'video':
        return <VideoIcon />;
      case 'pdf':
        return <PdfIcon />;
      case 'presentation':
        return <PresentationIcon />;
      default:
        return null;
    }
  };

  const renderMaterials = (materials: string[]) => {
    return materials.map(materialId => {
      const material = mockMaterials.find(m => m.id === materialId);
      if (!material) return null;

      return (
        <ListItem
          key={material.id}
          button
          onClick={() => handleMaterialClick(material)}
          sx={{ borderRadius: 1, '&:hover': { bgcolor: 'action.hover' } }}
        >
          <ListItemIcon>
            {getMaterialIcon(material.type)}
          </ListItemIcon>
          <ListItemText
            primary={material.title}
            secondary={material.type.toUpperCase()}
          />
        </ListItem>
      );
    });
  };

  const renderLesson = (lesson: Lesson, index: number) => {
    const isCompleted = completedLessons.has(lesson.id);
    const isActive = index === activeLesson;

    return (
      <Card 
        key={lesson.id}
        sx={{ 
          mb: 2,
          border: isActive ? 2 : 0,
          borderColor: 'primary.main',
          transition: 'all 0.3s ease'
        }}
      >
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6">
              {`${index + 1}. ${lesson.title}`}
              {isCompleted && <DoneIcon color="success" sx={{ ml: 1 }} />}
            </Typography>
            <Chip
              icon={<TimerIcon />}
              label={`${lesson.estimatedTime} мин`}
              variant="outlined"
              size="small"
            />
          </Box>

          <Typography variant="body1" sx={{ whiteSpace: 'pre-line', mb: 3 }}>
            {lesson.content}
          </Typography>

          {lesson.materials && lesson.materials.length > 0 && (
            <>
              <Typography variant="subtitle1" gutterBottom>
                Учебные материалы:
              </Typography>
              <List dense>
                {renderMaterials(lesson.materials)}
              </List>
            </>
          )}

          <Divider sx={{ my: 2 }} />

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Chip
              icon={<TestIcon />}
              label={`${lesson.test.questions.length} вопросов`}
              variant="outlined"
              size="small"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleStartTest(lesson.id)}
              disabled={!isActive}
            >
              Начать тест
            </Button>
          </Box>
        </CardContent>
      </Card>
    );
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!currentCourse) {
    return <Typography>Курс не найден</Typography>;
  }

  return (
    <Box sx={{ p: 3 }}>
      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="h4">{currentCourse.title}</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          {currentCourse.description}
        </Typography>
      </Paper>

      <Stepper activeStep={activeLesson} alternativeLabel sx={{ mb: 4 }}>
        {currentCourse.lessons.map((lesson, index) => (
          <Step key={lesson.id} completed={completedLessons.has(lesson.id)}>
            <StepLabel>{`Урок ${index + 1}`}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          {currentCourse.lessons.map((lesson, index) => renderLesson(lesson, index))}
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 3, position: 'sticky', top: 20 }}>
            <Typography variant="h6" gutterBottom>
              Прогресс курса
            </Typography>
            {/* Здесь можно добавить дополнительную информацию о прогрессе */}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CoursePage;